@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 300;
  src: local("Roboto Light"),
    url("./fonts/roboto-v27-latin-300.woff2") format("woff2"),
    url("./fonts/roboto-v27-latin-300.woff") format("woff"),
    url("./fonts/roboto-v27-latin-300.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: local("Roboto Regular"),
    url("./fonts/roboto-v27-latin-regular.woff2") format("woff2"),
    url("./fonts/roboto-v27-latin-regular.woff") format("woff"),
    url("./fonts/roboto-v27-latin-regular.ttf") format("truetype");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src: local("Roboto Medium"),
    url("./fonts/roboto-v27-latin-500.woff2") format("woff2"),
    url("./fonts/roboto-v27-latin-500.woff") format("woff"),
    url("./fonts/roboto-v27-latin-500.ttf") format("truetype");
}

:root {
  --black: #181519;
  --black2: #201e21;
  --white: #ffffff;
  --yellow: #b49b57;
  --grey: #969696;
  --grey2: #d4d4d4;
  --roboto: "Roboto", sans-serif;
}
html {
  scrollbar-color: #252226 #d4d4d4;
  scrollbar-width: thin;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  font-size: 62.5%;
}

body {
  font-family: var(--roboto);
  color: var(--white);
  background-color: var(--black);
  min-height: 100vh;
  overflow-y: scroll;
}

a {
  text-decoration: none;
  color: var(--white);
}

ul {
  list-style: none;
}

img,
video {
  width: 100%;
  height: auto;
}
button {
  outline: none;
  border: none;
  font-family: var(--roboto);
}
:after,
:before {
  content: "";
  position: absolute;
}
.grid {
  display: grid;
  grid-template: 10rem auto/1.1fr 3fr;
  column-gap: 6rem;
}
.spacer {
  padding: 0 clamp(1.6rem, 4.2vw, 4.2rem) 0;
  max-width: calc(1820px + 2 * clamp(1.6rem, 4.2vw, 4.2rem));
  margin: 0 auto;
}
nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  grid-row: 1/2;
  grid-column: 1/2;
}
.nav-flags {
  display: flex;
  align-items: center;
}
.nav-flags button{
  background: none;
}
nav svg {
  cursor: pointer;
}
.nav-flags button ~ button {
  margin-left: 1.6rem;
}
.menu {
  grid-row: 2/3;
  grid-column: 1/2;
  font-size: clamp(0.8rem, 0.6vw, 1rem);
  position: relative;
  height: auto;
}
.menu-2 {
  position: sticky;
  top: 2.6rem;
}
.content {
  grid-row: 2/3;
  grid-column: 2/3;
  display: grid;
  grid-gap: 2rem;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  margin-bottom: 6.4rem;
}
.content-2 {
  grid-row: 2/3;
  grid-column: 2/3;
}
.menu-button {
  display: flex;
  align-items: center;
  color: var(--white);
  background-color: var(--black2);
  width: 100%;
  min-width: 32rem;
  text-align: left;
  padding: 0.46em 0.8em;
  border-radius: 0.5em;
  font-weight: 400;
  font-size: 2.5em;
  box-shadow: inset 0 0 1px 1px rgba(250, 250, 250, 0.026),
    1px 1px 12px 1px rgba(0, 0, 0, 0.032);
  cursor: pointer;
  opacity: 0.36;
  filter: drop-shadow(0px 0px 6px rgba(0 0 0 / 10%));
  transition: opacity 0.52s cubic-bezier(0.2, 0, 0.2, 1);
}
.menu-button-count {
  background-color: var(--yellow);
  width: 1.6em;
  height: 1.2em;
  border-radius: 16px;
  margin-left: 0.5em;
  display: flex;
  align-items: center;
  justify-content: center;
}
.menu-button-count p {
  font-size: 0.8em;
}
.dropdown-button {
  margin-left: auto;
}
.dropdown-button div {
  width: 24px;
  height: 2px;
  background-color: var(--grey2);
  transition: all 0.52s cubic-bezier(0.2, 0, 0.2, 1);
}
.dropdown-button div + div {
  margin-top: 0.5rem;
}

.toggle .dropdown-button .line-1 {
  transform: rotate(-45deg) translate(-3px, 6px);
  transition: transform 0.52s cubic-bezier(0.2, 0, 0.2, 1);
}

.toggle .line-3 {
  transform: rotate(45deg) translate(-4px, -7px);
  transition: transform 0.52s cubic-bezier(0.2, 0, 0.2, 1);
}

.toggle .line-2 {
  opacity: 0;
  transition: opacity 0.34s cubic-bezier(0.2, 0, 0.2, 1);
}
.menu .menu-button + .menu-button {
  margin-top: 0.36em;
}
.toggle {
  opacity: 1;
}
.content-card {
  font-size: clamp(0.8rem, 0.62vw, 1rem);
  cursor: pointer;
  align-self: start;
  justify-self: center;
  width: 100%;
  position: relative;
  will-change: transform;
  overflow: hidden;
  background-color: #272b2b;
  border-radius: 10px;
}
.content-card .img-wrapper{
  transition: all 0.32s cubic-bezier(0.2, 0, 0.2, 1);
}
.content-card:hover .img-wrapper{
  transform: scale(1.02);
}
/* .content-card:active {
  transform: scale(0.99);
} */
.content-card img {
  margin-bottom: -3px;
  color: transparent;
  transition: opacity 0.1s cubic-bezier(0.2, 0, 0.2, 1);
}
.image-loading{
  opacity: 0;
  visibility: hidden;
}
.content-card-desc {
  position: absolute;
  background-color: rgba(27, 25, 28, 0.9);
  width: 94%;
  bottom: 1.4rem;
  left: 50%;
  transform: translateX(-50%);
  border-radius: 12px;
  padding: 1.5em 2em;
  display: flex;
  flex-direction: column;
  transition: width 0.26s cubic-bezier(0.2, 0, 0.2, 1);
}
.content-card-desc p:first-of-type {
  font-weight: 400;
  font-size: 2.4em;
  margin-bottom: 0.5em;
}
.content-card-desc p:last-of-type {
  color: var(--grey);
  font-weight: 300;
  font-size: 1.9em;
  margin-top: auto;
}
.content-card-desc:after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='%23b49b57'%3E%3Cpath d='M5 3l3.057-3 11.943 12-11.943 12-3.057-3 9-9z'/%3E%3C/svg%3E");
  right: 1.6em;
  top: 50%;
  transform: translateY(-50%);
}
.flag-inactive{
  opacity: 0.4;
}
.item-enter {
  opacity: 0;
}
.item-enter-active {
  opacity: 1;
  transition: opacity 500ms ease-in;
}
.item-exit {
  opacity: 1;
}
.item-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in;
}
.content-2 {
  font-size: clamp(0.86rem, 0.6vw, 1rem);
  display: grid;
  grid-template: auto auto 1fr / 2fr 1fr;
  column-gap: 2.4rem;
}
.content-2-left {
  grid-row: 1/3;
  grid-column: 1/2;
  /* align-self: start; */
}
.content-2-ingridients {
  grid-row: 2/4;
  grid-column: 2/3;
}
.content-2-left-2 {
  grid-row: 3/4;
  grid-column: 1/2;
  /* align-self: start; */
}
.content-title {
  font-size: 3em;
  letter-spacing: 1px;
  font-weight: 500;
  margin-bottom: 0.6em;
  grid-row: 1/2;
  grid-column: 2/3;
}
.content-category {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.content-2-ingridients .content-category{
  margin-bottom: 1.6rem;
}
.content-category-title {
  font-size: 2.4em;
  font-weight: 400;
  margin-left: 0.8rem;
  letter-spacing: 0.4px;
}
.content-2-left video {
  margin-bottom: 1em;
}
.content-category p {
  margin-left: auto;
  font-size: 1.8em;
  color: var(--grey);
}
.content-ingridient {
  display: flex;
  margin-top: 1em;
  color: var(--grey2);
  font-weight: normal;
}
.ing-1 {
  font-size: 2.2em;
}
.ing-2 {
  margin-left: auto;
  color: var(--white);
  background-color: var(--yellow);
  border-radius: 16px;
  font-size: 2em;
  padding: 0.1em 0.5em;
  letter-spacing: -0.4px;
}
.content-recipe {
  font-size: 2em;
  color: var(--grey2);
  margin-top: 0.4em;
}
.line {
  background-color: var(--white);
  height: 1px;
  width: 100%;
  margin-top: 1em;
  opacity: 0.08;
}
.background{
  background-image: url(images/bg-1920.webp);
  min-height: 100vh;
  width: 100%;
  background-position: center;
}
.welcome-flex{
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
}
.background svg{
  margin-top: -10rem;
  width: 32em;
}
.background a{
  color: var(--white);
  background-color: var(--yellow);
  border-radius: 64px;
  font-size: 3.2em;
  padding: 0.24em 1em;
  letter-spacing: -0.4px;
  font-weight: 400;
  transition: all 0.32s cubic-bezier(0.2, 0, 0.2, 1);
  cursor: pointer;
  margin-top: 6.4rem;
}
.background a:active{
transform: scale(0.98);
}
.background a:hover{
  opacity: 0.9;
}
@media (max-width: 1440px) {
  .grid {
    column-gap: 3.2rem;
  }
  .content {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}
@media (max-width: 1360px) {
  .nav-flags svg {
    /* width: 28px;
    height: 22px; */
    transform: scale(0.88);
  }
  .nav-flags div ~ div {
    margin-left: 0.9rem;
  }
}


@media (max-width: 1280px) {
  .grid {
    grid-template: 8rem auto/1.1fr 3fr;
  }
  .nav-logo {
    width: 160px;
    height: 42px;
  }
  .background{
    background-image: url(images/bg-1280.webp);
  }
}

@media(max-width: 1279px) {
  .content-2 {
    font-size: 0.82rem;
    display: grid;
    grid-template: auto 1fr auto auto/ 1fr;
    column-gap: 2.4rem;
  }
  .content-title {
    grid-row: 1/2;
    grid-column: 1/2;
  }
  .content-2-left {
    grid-row: 2/3;
    grid-column: 1/2;
  }
  .content-2-left-2 {
    grid-row: 4/5;
    grid-column: 1/2;
    margin-top: 3.2rem;
  }
  .content-2-ingridients {
    grid-row: 3/4;
    grid-column: 1/2;
    margin-top: 1.4rem;
  }
}

@media (max-width: 1152px) {
  .content-card {
    font-size: clamp(0.8rem, 0.8vw, 1rem);
  }
}
@media (max-width: 1024px) {
  .grid {
    margin-bottom: 6.4rem;
  }
  .content {
    grid-gap: 1.4rem;
  }
  .menu-button {
    padding: 0.5em 0.8em;
    font-size: 2.3em;
  }
  .menu .menu-button + .menu-button {
    margin-top: 0.36em;
  }
}
@media (max-width: 1023px) {
  .grid {
    display: block;
  }
  .menu-button {
    padding: 0.5em 0.8em;
    font-size: 2.6em;
  }
  .menu .menu-button + .menu-button {
    margin-top: 0.4em;
  }
  .menu .menu-button + .menu-button {
    margin-top: unset;
  }
  nav {
    margin-bottom: 4.2rem;
    height: 6.4rem;
  }
  .dropdown-button {
    display: none;
  }
  .menu {
    font-size: clamp(0.8rem, 1.4vw, 0.9rem);
    white-space: nowrap;
    margin-bottom: 3.6rem;
  }
  .menu-2 {
    top: unset;
    position: unset;
    display: flex;
    flex-wrap: wrap;
    gap: 1em;
    justify-content: center;
  }
  .menu-button {
    min-width: unset;
    font-weight: 300;
    font-size: 2.4em;
    flex: 0 1 150px;
  }
  .menu-button-count p {
    font-size: 0.8em;
  }
  .dropdown-button {
    margin-left: auto;
  }
  .content-2{
    font-size: clamp(0.86rem, 1.5vw, 1rem)
  }
}
@media (max-width: 900px) {
  .background{
    background-image: url(images/bg-900.webp);
  }
}

@media (max-width: 882px) {
  .content-card {
    font-size: clamp(0.78rem, 1.1vw, 1rem);
  }
}
@media (max-width: 601px) {
  .content {
    display: grid;
    grid-gap: 2rem;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
  }
}
@media (max-width: 588px) {
  .content-card {
    font-size: clamp(0.9rem, 2vw, 1rem);
  }
}
@media (max-width: 480px) {
  .background{
    background-image: url(images/bg-480.webp);
  }
  .mobile-menu {
    position: fixed;
    bottom: 0;
    background-color: var(--black2);
    width: 100%;
    height: 6.4rem;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .mobile-menu svg {
    fill: #FFF;
  }
  nav{
    margin-bottom: 2.4rem;
  }
}
